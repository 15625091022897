import { Trans } from '@lingui/macro'
import Link from 'next/link'
import { FC } from 'react'

import { pushCleverEvent } from '../../../api/analytics/clevertap'
import Arrow from './arrow'
import styles from './Stage.module.css'

interface Props {
  section: string
}

const feedAge = 'pregnant'

const StagePregnant: FC<Props> = props => (
  <Link href='/lifestages/pregnant'>
    <a className={styles.stage} onClick={() => pushCleverEvent('click', { feedAge, keyAction: 'seemore', section: props.section })}>
      <div className={styles.stageImage}>
        <img alt='pregnant' className={styles.image} src='https://d2wp562hbbs2nm.cloudfront.net/web/images/Stage_Pregnancy.jpeg' />
        <div className={styles.imageText}>
          <h2>
            <Trans>I AM PREGNANT</Trans>
          </h2>
          <Arrow size='30px' />
        </div>
      </div>
      <ul>
        <li>
          <Trans>Pregnancy week by week tracker</Trans>
        </li>
        <li>
          <Trans>Doctor&apos;s approved daily tips &amp; medically rich content</Trans>
        </li>
        <li>
          <Trans>Weekly diet chart for a healthy pregnancy</Trans>
        </li>
        <li>
          <Trans>Complete guidance with an active network of 10M+ Moms</Trans>
        </li>
        <li>
          <Trans>Highly relevant premium quality products for happy motherhood</Trans>
        </li>
      </ul>
      <div className={styles.seeMore}>
        <strong>
          <Trans>KNOW MORE</Trans>
        </strong>
      </div>
    </a>
  </Link>
)

export default StagePregnant
