import { Trans } from '@lingui/macro'
import Link from 'next/link'
import { FC } from 'react'

import { pushCleverEvent } from '../../../api/analytics/clevertap'
import Arrow from './arrow'
import styles from './Stage.module.css'

interface Props {
  section: string
}

const feedAge = 'mother'

const StageMother: FC<Props> = props => (
  <Link href='/lifestages/mother'>
    <a className={styles.stage} onClick={() => pushCleverEvent('click', { feedAge, keyAction: 'seemore', section: props.section })}>
      <div className={styles.stageImage}>
        <img alt='mother' className={styles.image} src='https://d2wp562hbbs2nm.cloudfront.net/web/images/Stage_Parenting.jpeg' />
        <div className={styles.imageText}>
          <h2>
            <Trans>I AM ALREADY A PARENT</Trans>
          </h2>
          <Arrow size='30px' />
        </div>
      </div>
      <ul>
        <li>
          <Trans>Baby growth &amp; development tracker</Trans>
        </li>
        <li>
          <Trans>Doctor&apos;s approved daily tips &amp; medically rich content</Trans>
        </li>
        <li>
          <Trans>Baby diet chart &amp; unlimited food recipes</Trans>
        </li>
        <li>
          <Trans>Active Network of 10M+ Moms for any time guidance</Trans>
        </li>
        <li>
          <Trans>Premium quality &amp; 100% safe products for baby &amp; mommy</Trans>
        </li>
      </ul>
      <div className={styles.seeMore}>
        <strong>
          <Trans>KNOW MORE</Trans>
        </strong>
      </div>
    </a>
  </Link>
)

export default StageMother
